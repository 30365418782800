.modal-trade-mobile-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid #FFDC87;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #22292E;
}

.modal-trade-mobile-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.modal-trade-mobile-items-container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 82px;
}

.modal-trade-mobile-circle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.003em;
    color: #22292E;
}

.modal-trade-mobile-circle {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FCAD2A;
    background: #FFF8E8;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
    letter-spacing: 0.003em;
}

.modal-trade-sell-icon {
    background: url("../../../images/mobile/trade/modal-sell.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.modal-trade-buy-icon {
    background: url("../../../images/mobile/trade/modal-buy.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}