.graph {
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px;
}

.graph-top-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 50px;
}

.graph-top-container-icons {
    display: flex;
}

.graph-top-container-eur-icon {
    background: url("../../../../images/icons/eur.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}

.graph-top-container-lmgt-icon {
    background: url("../../../../images/icons/lmgt.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}

.graph-top-container-reload-icon {
    background: url("../../../../images/icons/reload.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.graph-top-container-currencies {
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    margin-left: 6px;
    margin-right: 6px;
}

.graph-top-container-currencies-lmgt {
    color: #8B8CA7;
}

.graph-top-container-rate {
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 6px;
}

.graph-top-container-rate-change {
    display: flex;
    column-gap: 6px;
    align-items: center;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.graph-top-container-rate-change-green {
    color: #6BBB72;
}

.graph-top-container-prices-header {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #949494;
}

.graph-sort-icon {
    background: url("../../../../images/icons/sort.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.graph-progress-bar {
    display: flex;
    width: 156px;
    height: 3px;
    background: #C7C7CC;
    margin-top: 12px;
    margin-bottom: 6px;
}

.graph-progress-bar-item {
    width: 50%;
    height: 3px;
}

.graph-progress-bar-item-active {
    background: #1741D3;
}

.graph-top-container-prices {
    display: flex;
    justify-content: space-between;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #414141;
}

.graph-top-container-prices > div {
    display: flex;
    column-gap: 12px;
}

.graph-top-rate-buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.graph-top-rate-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 24px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    cursor: pointer;
}

.graph-top-rate-button-active {
    background: #3B3C4E;
}


