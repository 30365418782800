.trade {
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    height: auto;
    padding: 15px;
}

.trade-title {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
}

.trade-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #8A8A8E;
}

.trade-tabs > div {
    margin-top: 15px;
    cursor: pointer;
    border-bottom: 2px solid #C7C7CC;
    width: 128px;
    height: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.trade-tabs-active-tab {
    border-bottom: 2px solid #0D80DC !important;
}

.trade-wallet {
    width: 256px;
    height: 38px;
    margin: 20px auto 0;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #8E8E93;
}

.trade-wallet > div {
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
    row-gap: 8px;
    flex-wrap: wrap;
    min-width: 50%;
}

.trade-wallet-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
}

.trade-wallet-icon {
    background: url("../../../../images/icons/wallet.png");
    background-size: contain;
    width: 12px;
    height: 12px;
}

.trade-lmgt-icon {
    background: url("../../../../images/icons/lmgt-grey.png");
    background-size: contain;
    width: 16px;
    height: 16px;
}

.trade-form {
    margin: 16px auto 0;
    width: 256px;
}


.trade-input {
    border: 1px solid #C7C7CC;
    border-radius: 6px;
    width: 256px;
    height: 56px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;
}

.trade-input-element {
    border: none !important;
    border-radius: unset !important;
    padding: 0 !important;
    background: none !important;
    width: 100px !important;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 16px;
    color: #000000;
}

.trade-input-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 7px;
}


.trade-input-title {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #979592;
}

.trade-input-lmgt-icon {
    background: url("../../../../images/icons/lmgt.png") no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
}

.trade-input-eur-icon {
    background: url("../../../../images/icons/eur.png") no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
}

.trade-input-data-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 7px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #979592;
}

.trading-total {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #434343;
    margin-bottom: 20px;
    margin-top: 15px;
}

.trade-button-container {
    display: flex;
    justify-content: center;
}

.trade-button {
    min-width: 197px;
    width: auto;
    height: 44px !important;
    font-family: 'Inter', serif;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px;
}
