.deposit-form {
    width: 343px;
    margin: 0 auto;
    color: #8A8A8E;
    font-size: 14px;
    font-family: 'Inter', serif;
}

.deposit-title {
    text-align: center;
    margin-bottom: 27px;
}

.deposit-item-container {
    margin-bottom: 20px;
}

.deposit-input-title {

}

.deposit-input-element {
    border: 1px solid #FCAD2A !important;
    background: #FFFFFF !important;
}

.deposit-fixed-amount-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.deposit-fixed-amount-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 48px;
    color: #FCAD2A;
    border: 1px solid #FCAD2A;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
}

.deposit-fixed-amount-item-active {
    background: rgba(252, 173, 42, 0.2)
}

.deposit-form-qr {
    width: 300px;
    margin: 0 auto;
}

.deposit-form-qr-info-container {
    width: 300px;
    background: #F8F9FB;
    border-radius: 8px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8A8A8E;
    margin-top: 16px;
    margin-bottom: 80px;
    text-align: center;
    padding: 14px;
}

.deposit-form-qr-info-caption {
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #22292E;
    padding-bottom: 14px;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 7px;
}

.deposit-form-qr-code {
    margin-top: 7px;
}

.deposit-form-qr-image {
    text-align: center;
}

.deposit-button-container {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    margin-top: 26px;
}

.deposit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    width: 96px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    color: #FCAD2A;
    border: 1px solid #FCAD2A;
    border-radius: 4px;
    cursor: pointer;
}

.deposit-button-copy-icon {
    background: url("../../../images/deposits/copy.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}

.deposit-button-share-icon {
    background: url("../../../images/deposits/share.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}