.dashboard-layout {
    background: url("../../../images/landing.jpg") no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    padding-top: 30px;
}

@media screen and (max-width: 1000px){
    .dashboard-layout {
        background: #FFFFFF;
        padding: 0 0 115px;
        overflow-y: scroll;
    }
}

.dashboard-content {
    width: 1320px;
    margin: auto;
}

@media screen and (max-width: 1000px){
    .dashboard-content {
        width: 100%;
        margin: 0 auto;
    }
}