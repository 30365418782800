.top-panel {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.top-panel-left {

}

.top-panel-right {

}

.top-panel-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 60px;
}

.top-panel-item {
    width: 150px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 0 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #969B9F;
}

.top-panel-item-active {
    color: #000000;
}

.top-panel-icon-trading {
    background: url("../../images/top-panel/trading-gray.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.top-panel-icon-trading-active {
    background: url("../../images/top-panel/trading.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.top-panel-icon-account {
    background: url("../../images/top-panel/wallet-gray.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.top-panel-icon-account-active {
    background: url("../../images/top-panel/wallet.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.top-panel-icon-portfolio {
    background: url("../../images/top-panel/portfolio-gray.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.top-panel-icon-portfolio-active {
    background: url("../../images/top-panel/portfolio.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.top-panel-right-container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
}

.login-bt-button {
    width: 158px;
    height: 44px;
    border: 1px solid #8E8E93;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #8E8E93;
}

.logout-button {
    background: url("../../images/top-panel/logout.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
}