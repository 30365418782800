.portfolio-top-panel {
    width: 100%;
    height: 94px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 65px;
    background: #FFFFFF;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    border-radius: 9px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
}

.portfolio-top-panel-grey-label {
    color: #8E8E93;
}

.portfolio-mobile-container {
    padding: 16px;
    margin: 0 auto;
    max-width: 600px;
}

.portfolio-mobile-header {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    color: #000000;
}

.portfolio-mobile-username {
    font-weight: 700;
}

.portfolio-mobile-box {
    background: url("../../images/mobile/price/price-box.png") no-repeat;
    background-size: contain;
    width: 1000px;
    max-width: 100%;
    height: 150px;
    overflow: hidden;
    padding: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 13px;
    margin-bottom: 24px;
    margin-top: 30px;
    color: #F8F8F8;
    font-family: 'Inter', serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
}

@media screen and (min-width: 400px) {
    .portfolio-mobile-box {
        height: 200px;
    }
}

@media screen and (min-width: 768px) {
    .portfolio-mobile-box {
        height: 270px;
    }
}

.portfolio-mobile-box-title {
    font-size: 14px;
}

.portfolio-mobile-box-balances {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portfolio-mobile-lmgt-balance {
    display: flex;
    align-items: center;
    column-gap: 7px;

}

.portfolio-mobile-lmgt-icon {
    background: url("../../images/mobile/portfolio/lmgt.png") no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.portfolio-mobile-bg {
    background: url("../../images/mobile/portfolio/bg.png") no-repeat;
    background-size: contain;
    width: 600px;
    height: 320px;
    max-width: 100%;
}