.trade-mobile-container {
    padding: 16px;
}

.trade-mobile-title {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #000000;
    margin-bottom: 23px;
}

.trade-mobile-input-inner-box-left {
    width: 100%;
    height: 100%;
}

.trade-mobile-input-inner-box-right {
    padding: 11px;
    width: 50%;
    height: 100%;
}

.trade-mobile-input-box-left {
    padding: 11px;
    position: absolute;
    left: 0;
    height: 73px;
    width: 50%;
    background: linear-gradient(90deg, #F3A128 0%, #F5DA30 100%);
    box-shadow: 5px 0 15px rgba(112, 109, 109, 0.25);
    border-radius: 8px;
    /*transform: rotate(-180deg);*/
}

.trade-mobile-input-box-right {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 73px;
    width: 100%;
    background: #F1B442;
    border-radius: 8px;
}

.trade-mobile-input-label {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.0008em;
    color: #F8F8F8;
    margin-bottom: 3px;
}

.trade-mobile-input {
    background: #FFFFFF !important;
    border-radius: 8px !important;
    width: 100%;
    height: 32px !important;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px !important;
    line-height: 28px;
    text-align: center;
    color: #22292E;
    border: none !important;
}

.trade-mobile-table {
    margin-top: 27px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.trade-mobile-table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trade-mobile-table-cell {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.003em;
    color: #22292E;
}

.trade-mobile-table-cell:first-child {
    text-align: left;
}

.trade-mobile-table-cell:last-child {
    text-align: right;
    font-weight: 800;
    font-size: 17px;
    line-height: 22px;
}

.trade-mobile-table-cell-bold {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.trade-mobile-deposit-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    cursor: pointer;
    margin-bottom: 47px;
}

.trade-mobile-deposit-button-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    min-width: 48px;
    height: 48px;
    background: #F2F2F7;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 400;
}

.trade-mobile-deposit-button-label {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.trade-mobile-deposit-button-next {
    background: url("../../../../images/mobile/trade/next.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}