.deposits {
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    height: 87px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 14px;
}

@media screen and (max-width: 1000px) {
    .deposits {
        box-shadow: none !important;
        flex-direction: column;
        row-gap: 24px;
        column-gap: unset;
        margin-top: 51px;
    }
}

.deposits-mobile-title {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #000000;
    margin-top: 16px;
}

.deposits-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 266px;
    column-gap: 10px;
}

.deposits-item-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2px;
}

.deposits-item-title {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.deposits-item-description {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8A8A8E;
}

.deposits-arrow-icon {
    background: url("../../../../images/deposits/arrow.png") no-repeat;
    background-size: contain;
    width: 8px;
    height: 13px;
}

