.order-book {
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px;
}

@media screen and (max-width: 1000px){
    .order-book {
        box-shadow: none !important;
    }
}

.order-book-title {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 17px;
}

.trading-price {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #237BF1;
    border-radius: 6px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    width: 197px;
    height: 45px;
    margin: 25px auto;
}

@media screen and (max-width: 1000px){
    .trading-price {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
}