.oc-orders {
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    min-height: 257px;
    padding: 15px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px) {
    .oc-orders {
        box-shadow: none !important;
    }
}

.oc-orders-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 20px;
}

.oc-orders-tabs-item {
    cursor: pointer;
    color: #979592;
}

.oc-orders-tabs-item-active {
    color: #333333;
}

.oc-orders-table > tbody > tr > td, .oc-orders-table > thead > tr > th {
    border-bottom: none;
    padding: 0 0 5px;
    vertical-align: center;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #969B9F;
}

.oc-orders-table > tbody > tr {
    padding-bottom: 5px;
}

@media screen and (max-width: 1000px) {
    .oc-orders-table > thead > tr > th {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.003em;
        color: #22292E;
    }

    .oc-orders-table > tbody > tr > td {
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
    }
}