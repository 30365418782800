.orders-mobile {

}

.orders-mobile-header {
    position: relative;
    height: 72px;
    background: linear-gradient(90deg, #F5DA30 0%, #F3A128 100%);
}

.orders-mobile-header-icon {
    background: url("../../../../images/mobile/header-icon.png") no-repeat;
    background-size: contain;
    width: 70px;
    height: 70px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 37px;
}

.orders-mobile-header-text {
    text-align: center;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    color: #8A8A8E;
    margin-top: 44px;
}

.orders-mobile-header-text-title {
    color: #22292E;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 8px;
}

.orders-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.orders-mobile-menu-item {
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #8A8A8E;
    padding: 8px 16px;
    border-radius: 8px;
}

.orders-mobile-menu-item-active {
    color: #FCAD2A;
    border: 1px solid #FCAD2A;
}