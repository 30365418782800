.dashboard-main {
    display: flex;
    margin-top: 36px;
    justify-content: space-between;
}

.dashboard-main-left {
    display: flex;
    flex-direction: column;
    width: 358px;
    row-gap: 23px;
}

.dashboard-main-right {
    width: 254px;
}

.dashboard-main-center {
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    width: 667px;
}