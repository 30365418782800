.guest-body {
    background: url("../../../images/landing.jpg") no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
}

@media screen and (max-width: 1024px){
    .guest-body {
        background: url("../../../images/landing.jpg") no-repeat 78%;
        background-size: cover;
    }
}



.guest-content {
    max-width: 1200px;
    margin: 0 auto;
}