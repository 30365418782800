.footer-mobile {
    position: fixed;
    height: 48px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.25);
}

.footer-mobile-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    position: relative;
}

.footer-mobile-side {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
}

.footer-mobile-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 27px;
}

.footer-mobile-menu-item {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #C7C7CC;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    justify-content: center;
}

.footer-mobile-menu-item-active {
    color: #FCAD2A !important;
}

.footer-mobile-button {
    background: url("../../images/mobile/footer-button.png") no-repeat;
    background-size: contain;
    width: 50px;
    height: 50px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -24px;
    text-align: center;
}

.footer-mobile-menu-orders-icon {
    background: url("../../images/mobile/footer-menu/orders.png") no-repeat;
    background-size: contain;
    width: 14.4px;
    height: 18px;
}

.footer-mobile-menu-orders-icon-active {
    background: url("../../images/mobile/footer-menu/active/orders.png") no-repeat;
    background-size: contain;
    width: 14.4px;
    height: 18px;
}

.footer-mobile-menu-portfolio-icon {
    background: url("../../images/mobile/footer-menu/portfolio.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}

.footer-mobile-menu-portfolio-icon-active {
    background: url("../../images/mobile/footer-menu/active/portfolio.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}

.footer-mobile-menu-prices-icon {
    background: url("../../images/mobile/footer-menu/prices.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}

.footer-mobile-menu-prices-icon-active {
    background: url("../../images/mobile/footer-menu/active/prices.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}

.footer-mobile-menu-account-icon {
    background: url("../../images/mobile/footer-menu/account.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 16.2px;
}

.footer-mobile-menu-account-icon-active {
    background: url("../../images/mobile/footer-menu/active/account.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 16.2px;
}

