.auth-logo {
    width: 406px;
    height: 90px;
    background: url("../../images/logo.png") no-repeat;
    background-size: cover;
    margin-bottom: 21px;
}

@media screen and (max-width: 1000px) {
    .auth-logo {
        width: 289px;
        height: 64px;
        margin: 0 auto 21px;
    }
}

.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
}

@media screen and (max-width: 1000px) {
    .auth-container {
        max-width: 100% !important;
    }
}

.auth-form {
    width: 340px;
    margin: 0 auto;
}