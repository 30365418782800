.login-history-table > tbody > tr > td, .login-history-table > thead > tr > th {
    border-bottom: none;
    padding: 0 0 5px;
    vertical-align: center;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #969B9F;
}

.login-history-table > thead > tr > th {
    color: #000000;
    padding-bottom: 10px;
}

.login-history-table > tbody > tr {
    padding-bottom: 5px;
}

@media screen and (max-width: 1000px) {
    .login-history-table > thead > tr > th {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.003em;
        color: #22292E;
    }

    .login-history-table > tbody > tr > td {
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
    }
}

.account-content {
    background: #FFFFFF;
    box-shadow: 0 15px 37px rgba(191, 21, 108, 0.05);
    border-radius: 9px;
    padding: 33px 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 100px;
    margin-top: 30px;
}

@media screen and (max-width: 1024px) {
    .account-content {
        flex-direction: column;
        height: 100%;
        background: none !important;
        border: none !important;
        box-shadow: none !important;
    }
}

.account-content > div {
    width: 50%;
}

@media screen and (max-width: 1024px) {
    .account-content > div {
        width: 100%;
    }

}

.account-content-title {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 12px;
}

.account-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    row-gap: 13px;
}

.account-form-item {
    display: flex;
    position: relative;
}

.account-form-icon {
    position: absolute;
    top: 6px;
    left: 6px;
}

.account-username-icon {
    background: url("../../images/account/username.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}

.account-phone-icon {
    background: url("../../images/account/phone.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.account-mail-icon {
    background: url("../../images/account/mail.png") no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
}

.account-country-icon {
    background: url("../../images/account/country.png") no-repeat;
    background-size: contain;
    width: 19px;
    height: 19px;
}

.account-input {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px;
    letter-spacing: 0.003em;
    color: #000000;
    background: #FFFFFF !important;
    border: 1px solid #B9B9BB !important;
    border-radius: 6px !important;
    padding: 9px !important;
    padding-left: 40px !important;
}

.account-funds-table {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.003em;
    color: #22292E;
    width: 200px;
}

@media screen and (max-width: 1024px){
    .account-funds-table {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.003em;
        color: #22292E;
        width: 100%;
    }
}

.account-funds-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-logout-icon {
    background: url("../../images/account/logout.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.account-logout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin-top: 100px;
    color: #969B9F;
    text-decoration: underline;
    cursor: pointer;
}

.account-mobile-header {
    position: relative;
    height: 72px;
    background: linear-gradient(90deg, #F5DA30 0%, #F3A128 100%);
}