.prices-mobile-container {
    padding: 16px;
    margin: 0 auto;
    max-width: 600px;
}

.prices-mobile-title {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #000000;
    margin-bottom: 23px;
}

.prices-mobile-box {
    background: url("../../images/mobile/price/price-box.png") no-repeat;
    background-size: contain;
    width: 1000px;
    max-width: 100%;
    height: 150px;
    overflow: hidden;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 16px;
}

@media screen and (min-width: 400px) {
    .prices-mobile-box {
        height: 200px;
    }
}

@media screen and (min-width: 768px) {
    .prices-mobile-box {
        height: 270px;
    }
}

.prices-mobile-logo-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.0035em;
    color: #F8F8F8;
}

.prices-mobile-lmgt-logo {
    background: url("../../images/mobile/price/lmgt.png") no-repeat;
    background-size: contain;
    width: 36px;
    height: 36px;
}

.prices-mobile-box-bottom-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.prices-mobile-data {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.prices-mobile-data-rate {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.0035em;
    color: #F8F8F8;
}

.prices-mobile-data-change {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.0008em;
    color: #F8F8F8;
}

.prices-mobile-box-items-container {
    display: flex;
    column-gap: 4px;
}

.prices-mobile-box-item {
    width: 88px;
    height: 54px;
    border-radius: 4px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    color: #F8F8F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.prices-mobile-box-item > div:last-child {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.prices-mobile-box-item-green {
    background: #6BBB72;
}

.prices-mobile-box-item-red {
    background: #FF6466;
}

.prices-mobile-about {
    width: 100%;
    padding: 21px;
    margin-top: 16px;
    background: #F8F9FB;
    border-radius: 8px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
}

.prices-mobile-about-title {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #22292E;
    margin-bottom: 8px;
}

.prices-mobile-graph {
    margin-bottom: 16px;
}

